
import { hierarchicalMenu, panel } from 'instantsearch.js/es/widgets';

export const INSTANT_SEARCH_HIERARCHICAL_ATTRIBUTE = 'taxonomies.language';
export const INSTANT_SEARCH_REGION_ATTRIBUTE = 'taxonomies.regions'; 
export const INSTANT_SEARCH_POST_TYPE_ATTRIBUTE = 'post_type_label'; 
export const INSTANT_SEARCH_COUNTRIES_ATTRIBUTE = 'taxonomies.countries'; 

export const hierarchicalMenuWithPostTypeHeader = panel({
  templates: { header: 'Post Type' },
})(hierarchicalMenu);


export const hierarchicalMenuWithHeader = panel({
  templates: { header: 'Languages' },
})(hierarchicalMenu);

export const hierarchicalMenuWithRegionsHeader = panel({
  templates: { header: 'Regions' },
})(hierarchicalMenu);

export const hierarchicalMenuWithCountriesHeader = panel({
  templates: { header: 'Countries' },
})(hierarchicalMenu);


