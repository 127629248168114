import instantsearch from 'instantsearch.js';
import { connectSearchBox } from 'instantsearch.js/es/connectors';
import historyRouter from 'instantsearch.js/es/lib/routers/history';
import {
  configure,
  hierarchicalMenu,
  hits,
  pagination,
  panel,
} from 'instantsearch.js/es/widgets';
import { 
  INSTANT_SEARCH_HIERARCHICAL_ATTRIBUTE, 
  INSTANT_SEARCH_REGION_ATTRIBUTE, 
  hierarchicalMenuWithHeader, 
  hierarchicalMenuWithRegionsHeader,
  INSTANT_SEARCH_POST_TYPE_ATTRIBUTE,
  hierarchicalMenuWithPostTypeHeader,
  hierarchicalMenuWithCountriesHeader,
  INSTANT_SEARCH_COUNTRIES_ATTRIBUTE
} from './hierarchicalMenus';

import { debounce } from './debounce';
import { searchClient } from './searchClient';

export const INSTANT_SEARCH_INDEX_NAME = 'wp_search_prodsearchable_posts';

const instantSearchRouter = historyRouter();

export const search = instantsearch({
  searchClient,
  indexName: INSTANT_SEARCH_INDEX_NAME,
  routing: instantSearchRouter,
});
const virtualSearchBox = connectSearchBox(() => {});

search.addWidgets([
  configure({
    attributesToSnippet: ['name:7', 'description:15'],
    snippetEllipsisText: '…',
    attributesToHighlight: ['post_title', 'description', 'content'], 
  }),
  virtualSearchBox(),
  hierarchicalMenuWithHeader({
    container: '#categories',
    attributes: [
      INSTANT_SEARCH_HIERARCHICAL_ATTRIBUTE,
      'taxonomies',
    ],
  }),
  hierarchicalMenuWithRegionsHeader({
    container: '#regions',
    attributes: [
      INSTANT_SEARCH_REGION_ATTRIBUTE,
    ],
  }),
  hierarchicalMenuWithPostTypeHeader({
    container: '#post_type',
    attributes: [
      INSTANT_SEARCH_POST_TYPE_ATTRIBUTE,
    ],
  }),
  hierarchicalMenuWithCountriesHeader({
    container: '#countries',
    attributes: [
      INSTANT_SEARCH_COUNTRIES_ATTRIBUTE,
    ],
  }),
  hits({
    container: '#hits',
    transformItems(items) {
      return items.map((item) => {
        const imageUrl = item.images && item.images.thumbnail ? item.images.thumbnail.url : '';
        
        return {
          ...item,
          category: item.post_title,
          image: imageUrl,
          link: item.permalink,
          postType: item.post_type
        };
      });
    },
    templates: {
      item: (hit, { html, components }) =>  html`
        <a href="${hit.link}" class="hit" style="display: flex;">
          <div class="hit-image">
            <img src="${hit.image}" alt="${hit.name}" />
          </div>
          <div style="width:80%;">
          <span class="hit_post-type">${hit.post_type}</span>
            <h1>${components.Highlight({ hit, attribute: 'post_title' })}</h1>  
            <div>
              <span>${components.Highlight({ hit, attribute: 'content' })}</span> 
            </div>
          </div>
          
        </a>
      `,
    },
  }),
  pagination({
    container: '#pagination',
    padding: 2,
    showFirst: false,
    showLast: false,
  }),
]);

export function setInstantSearchUiState(indexUiState) {
  search.setUiState((uiState) => ({
    ...uiState,
    [INSTANT_SEARCH_INDEX_NAME]: {
      ...uiState[INSTANT_SEARCH_INDEX_NAME],
      page: 1,
      ...indexUiState,
    },
  }));
}

export const debouncedSetInstantSearchUiState = debounce(
  setInstantSearchUiState,
  500
);

export function getInstantSearchCurrentCategory() {
  const indexUiState = search.getUiState()[INSTANT_SEARCH_INDEX_NAME];
  const hierarchicalMenuUiState = indexUiState && indexUiState.hierarchicalMenu;
  const currentCategories =
    hierarchicalMenuUiState &&
    hierarchicalMenuUiState[INSTANT_SEARCH_HIERARCHICAL_ATTRIBUTE];

  return currentCategories && currentCategories[0];
}


export function getInstantSearchUrl(indexUiState) {
  return search.createURL({ [INSTANT_SEARCH_INDEX_NAME]: indexUiState });
}

export function getInstantSearchUiState() {
  const uiState = instantSearchRouter.read();

  return (uiState && uiState[INSTANT_SEARCH_INDEX_NAME]) || {};
}
